<template>
  <div class="gws_address_search container-fluid-max mt-3 px-2">
    <h1 class="text-center">{{ t("nav.addresses") }}</h1>
    <div class="gws_control_bar d-lg-flex justify-content-between align-items-center gap-2 p-2 mb-2">
      <div class="d-lg-flex align-items-center gap-2">
        <!-- search input -->
        <div class="form-floating mb-2 mb-lg-0">
          <input
            type="text"
            class="form-control"
            id="searchName"
            :placeholder="t('search.name')"
            v-model="searchName"
            @input="search"
          />
          <label for="search">{{ t("search." + selectedSearchType) }}</label>
        </div>
        <!-- search type -->
        <div class="form-floating mb-2 mb-lg-0">
          <select
            class="form-select"
            id="search_type"
            aria-label="Select Search Type"
            v-model="selectedSearchType"
            @change="search"
          >
            <option v-for="searchType in searchTypes" :key="searchType.type" :value="searchType.type">
              {{ searchType.name }}
            </option>
          </select>
          <label for="search_type">{{ t("general.searchtype") }}</label>
        </div>
        <!-- categories -->
        <div v-if="categories.length > 0" class="form-floating mb-2 mb-lg-0">
          <select
            class="form-select"
            id="serach_categories"
            aria-label="Select Categorie"
            v-model="selectedCategoryId"
            @change="search"
          >
            <option value=""></option>
            <option v-for="category in categories" :key="category.id" :value="category.id">
              {{ category.category }}
            </option>
          </select>
          <label for="serach_categories">{{ t("general.categories") }}</label>
        </div>
        <!-- company -->
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            id="search_company"
            v-model="searchCompany"
            @change="search"
          />
          <label class="form-check-label" for="search_company">{{ t("general.company") }}</label>
        </div>
        <!-- person -->
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="search_person" v-model="searchPerson" @change="search" />
          <label class="form-check-label" for="search_person">{{ t("general.person") }}</label>
        </div>
        <!-- number of search results -->
        <div class="border border-light rounded p-1 mt-2 mt-lg-0">
          <small>{{ addresses.length }} {{ t("general.hits") }}</small>
        </div>
        <!-- reset -->
        <div>
          <button
            @click="resetSearch"
            type="button"
            class="btn btn-danger d-block p-0 mt-2 mt-lg-0"
            :title="t('general.reset')"
          >
            <svg class="bi" width="32" height="32" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#x"></use>
            </svg>
          </button>
        </div>
        <!-- loader -->
        <div v-if="searchLoader" class="spinner-border text-secondary global-loader" role="status"></div>
      </div>
      <!-- new -->
      <router-link
        to="/addresses/create"
        class="d-inline-block btn btn-success p-0 mt-2 mt-lg-0"
        :title="t('general.new')"
      >
        <svg class="bi" width="32" height="32" fill="currentColor">
          <use xlink:href="/assets/img/bootstrap-icons.svg#plus"></use>
        </svg>
      </router-link>
    </div>
    <!-- resluts -->
    <div class="card-group gws_card-group" v-if="addresses.length > 0">
      <div class="card gws_search_results mb-sm-2 text-dark me-2" v-for="address in addresses" :key="address.id">
        <div class="card-header d-flex justify-content-end">
          <router-link
            :to="'/addresses/edit/' + address.id"
            class="btn btn-success btn-icon me-2 is-rows-el edit-btn"
            :title="t('general.edit')"
          >
            <svg width="20" height="20" class="bi" fill="currentColor">
              <use xlink:href="/assets/img/bootstrap-icons.svg#pen"></use>
            </svg>
            {{ t("general.edit") }}
          </router-link>
        </div>
        <div class="card-body">
          <div class="card-text">
            <dl>
              <template v-if="address.name !== null">
                <dt>{{ t("address.name") }}</dt>
                <dd>{{ address.name }}</dd>
              </template>
              <template v-if="address.surname !== null">
                <dt>{{ t("address.surname") }}</dt>
                <dd>{{ address.salutation }} {{ address.forename }} {{ address.surname }}</dd>
              </template>
              <template v-if="address.email !== null">
                <dt>{{ t("address.email") }}</dt>
                <dd>
                  <a :href="'mailto:' + address.email">{{ address.email }}</a>
                </dd>
              </template>
              <template v-if="address.tel !== null">
                <dt>{{ t("address.tel") }}</dt>
                <dd>
                  <a :href="'tel:' + phone(address)">{{ phone(address) }}</a>
                </dd>
              </template>
              <template v-if="address.mobile !== null">
                <dt>{{ t("address.mobile") }}</dt>
                <dd>
                  <a :href="'tel:' + mobile(address)">{{ mobile(address) }}</a>
                </dd>
              </template>
              <template v-if="address.fax !== null">
                <dt>{{ t("address.fax") }}</dt>
                <dd>
                  {{ fax(address) }}
                </dd>
              </template>
              <template v-if="address.website !== null">
                <dt>{{ t("address.website") }}</dt>
                <dd>
                  <a :href="website(address.website)" target="_blank" rel="noopener noreferrer">{{
                    website(address.website)
                  }}</a>
                </dd>
              </template>
            </dl>
            <div class="d-flex mb-2">
              <span v-if="address.is_company == 1" class="badge rounded-pill bg-secondary me-1">{{
                t("general.company")
              }}</span>
              <span v-if="address.is_person == 1" class="badge rounded-pill bg-secondary me-1">{{
                t("general.person")
              }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <span
                v-for="category in address.categories"
                :key="category.id"
                :data-id="category.id"
                @click="setCategory(category.id)"
                class="badge rounded-pill bg-primary me-1 mb-1 cursor-pointer"
                >{{ category.category }}</span
              >
            </div>
          </div>
        </div>
        <!-- <div class="card-footer bg-transparent border-success">Footer</div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import { useRouter } from "vue-router";
import axios from "axios";
import storeConfig from "../../store/config";
import { useApiQuery } from "@/composables/useApiQueries";
import { phone, mobile, fax, website } from "@/composables/address.js";
export default {
  name: "addresses",
  setup() {
    const store = useStore();
    const router = useRouter();
    const searchName = ref("");
    const searchCompany = ref(false);
    const searchPerson = ref(false);
    const addresses = ref([]);
    const searchLoader = ref(false);
    // const categories = ref([]);
    const { result: categories, query: getCategories } = useApiQuery({ url: "category", defaultVal: [] });
    const selectedCategoryId = ref("");
    const { t } = useI18n();
    const searchTypes = ref([
      {
        type: "name",
        name: t("search.name"),
      },
      {
        type: "surname",
        name: t("search.surname"),
      },
      {
        type: "both",
        name: t("search.both"),
      },
    ]);
    const selectedSearchType = ref("name");

    if (store.getters.currentCategoryId > 0) {
      selectedCategoryId.value = store.getters.currentCategoryId;
    }

    if (store.getters.currentSearchCompany) {
      searchCompany.value = store.getters.currentSearchCompany;
    }

    if (store.getters.currentSearchPerson) {
      searchPerson.value = store.getters.currentSearchPerson;
    }

    if (store.getters.currentSearchName.length > 0) {
      searchName.value = store.getters.currentSearchName;
      search();
    }

    if (store.getters.selectedSearchType.length > 0) {
      selectedSearchType.value = store.getters.selectedSearchType;
    }

    async function search() {
      if (searchName.value.length > 1) {
        try {
          // await store.dispatch("addLoading", 1);
          searchLoader.value = true;
          let params = { name: searchName.value };
          if (selectedCategoryId.value > 0) {
            params.categoryid = selectedCategoryId.value;
            await store.dispatch("setCurrentCategoryId", selectedCategoryId.value);
          }
          if (searchPerson.value) {
            params.person = 1;
          }
          if (searchCompany.value) {
            params.company = 1;
          }
          if (selectedSearchType.value) {
            params.searchtype = selectedSearchType.value;
          }
          await store.dispatch("setCurrentSearchPerson", searchPerson.value);
          await store.dispatch("setCurrentSearchCompany", searchCompany.value);
          await store.dispatch("setSelectedSearchType", selectedSearchType.value);
          // console.log(params);
          // console.log(selectedCategoryId.value);
          // console.log(searchPerson.value);
          let result = await axios.get(storeConfig.apiBaseUrl + "search/addresses", {
            params: params,
          });
          addresses.value = result.data;
          await store.dispatch("setCurrentSearchName", searchName.value);
          // console.log(addresses.value);
        } catch (err) {
          if (err.response.status == 401) {
            store.dispatch("logout");
            router.push({ name: "Login" });
          }
          // console.log(err.response.data.message);
        } finally {
          // await store.dispatch("addLoading", -1);
          searchLoader.value = false;
        }
      } else {
        addresses.value = [];
      }
    }

    function setCategory(id) {
      selectedCategoryId.value = id;
      search();
    }

    async function resetSearch() {
      searchName.value = "";
      searchCompany.value = false;
      searchPerson.value = false;
      selectedCategoryId.value = "";
      selectedSearchType.value = "name";
      await store.dispatch("setCurrentSearchName", "");
      await store.dispatch("setCurrentSearchCompany", false);
      await store.dispatch("setCurrentSearchPerson", false);
      await store.dispatch("setCurrentCategoryId", 0);
      await store.dispatch("setSelectedSearchType", "name");
      addresses.value = [];
    }

    if (store.getters.jwt) {
      getCategories();
    }

    return {
      search,
      searchName,
      searchPerson,
      searchCompany,
      searchTypes,
      selectedSearchType,
      addresses,
      searchLoader,
      phone,
      mobile,
      fax,
      website,
      categories,
      selectedCategoryId,
      setCategory,
      resetSearch,
      t,
      // setSelectedCategoryId,
      // filteredAddresses: computed(() => addresses),
      // addresses: computed(() => store.getters.addresses.filter(job => job.billed !== true)),
      // addresses: computed(() => store.getters.addresses),
      // jobentry
    };
  },
};
</script>
